$(function() {
  PrologueUniComponent();

  function PrologueUniComponent() {

    FilterManager.init((response) => {
      showFilter();
    });

    FilterManager.getContact((response) => {
      fillForm(response);
      showFilter();
    });

    initMask();

    function initMask() {
      $('.PrologueUniComponent__top-input[name="phone"]').mask('+7(000)-000-00-00', {
        // задавать в инпуте
        //placeholder: '+7(___)-___-__-__',
        /*onComplete: function(cep) {

        }*/
      });
    }

    function fillForm(data) {
      let inputName  = '.PrologueUniComponent__top-input[name="name"]';
      let inputPhone = '.PrologueUniComponent__top-input[name="phone"]';
      let inputEmail = '.PrologueUniComponent__top-input[name="email"]';

      if(data.name) {
        $(inputName).val(data.name);
      }

      if(data.phone) {
        $(inputPhone).val(data.phone);
      }

      if(data.email) {
        $(inputEmail).val(data.email);
      }
    }

    function showFilter() {
      let overlay      = '.PrologueUniComponent__bottom-overlay';
      let overlayClass = 'PrologueUniComponent__bottom-overlay--show';

      $(overlay).removeClass(overlayClass);
    }
  }
});
